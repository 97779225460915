.monitoreo-a-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.portada-container {
    width: 100%;
    /* Ajusta según sea necesario */
    position: relative;
    overflow: hidden;
}

.portada-container img {
    filter: brightness(0.5) opacity(100%) contrast(100%) saturate(100%) hue-rotate(0deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    width: 100%;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    font-weight: 550;
    position: absolute;
    /* Asegúrate de usar una fuente adecuada */
    color: white;
    /* Cambia esto si necesitas otro color */
}

.text-overlay span {
    color: red;
    /* El color rojo para las letras específicas */
}

/*section de empresa*/

.company-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.box {
    border: 1px solid black;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    background-color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 1%;
}

.box h2 {
    font-size: 32px;
    margin-bottom: 10px;
}

.box p {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 33.6px;
        letter-spacing: 0.48px;
}

.box ul {
    list-style-type: disc;
    padding-left: 20px;
}

.box ul li {
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 33.6px;
        letter-spacing: 0.48px;
}

.highlight {
    color: red;
}

/*seccion llamado*/

.cta-container {
    background-color: white;
    color: black;
    text-align: center;
    padding: 40px 20px;
    font-size: 18px;
    line-height: 33.6px;
    letter-spacing: 0.48px;
}

.cta-container p {
    margin: 0;
}

.imagen-portada-calibrar {
    width: 100%;
    height: 800px;
    /* Ajusta según sea necesario */
    position: relative;
    overflow: hidden;
}

.imagen-portada-calibrar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0px;
}

/* Responsive para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .text-overlay {
        font-size: 2em;
        /* Reducir el tamaño del texto */
    }

    .portada-container {
        max-height: 400px;
        /* Ajustar altura máxima en pantallas pequeñas */
    }

    .descripcion-container-1 {
        width: 90%;
        /* Aumentar el ancho en pantallas más pequeñas */
        padding: 15px;
        /* Reducir el padding */
    }
}

@media screen and (max-width: 480px) {
    .text-overlay {
        font-size: 1.5em;
        /* Más pequeño para pantallas extra pequeñas */
    }

    .portada-container {
        max-height: 300px;
        /* Ajustar la altura máxima en pantallas extra pequeñas */
    }

    .descripcion-container-1 {
        width: 95%;
        /* Aumentar aún más el ancho */
        padding: 10px;
        /* Reducir más el padding */
        line-height: 1.4;
        /* Ajustar el espacio entre líneas */
    }
}

/* Pantallas medianas (tabletas) */
@media (max-width: 1024px) {
    .box {
        padding: 15px;
    }

    .box h2 {
        font-size: 22px;
    }

    .box p,
    .box ul li {
        font-size: 15px;
    }
}

/* Pantallas medianas (tabletas) */
@media (max-width: 768px) {
    .box {
        padding: 15px;
    }

    .box h2 {
        font-size: 22px;
    }

    .box p,
    .box ul li {
        font-size: 15px;
    }
}

/* Pantallas pequeñas (teléfonos) */
@media (max-width: 480px) {
    .box {
        padding: 10px;
    }

    .box h2 {
        font-size: 20px;
    }

    .box p,
    .box ul li {
        font-size: 14px;
    }
}

/* Pantallas medianas (tabletas) */
@media (max-width: 768px) {
    .cta-container {
        padding: 30px 15px;
        font-size: 16px;
    }
}

/* Pantallas pequeñas (teléfonos) */
@media (max-width: 480px) {
    .cta-container {
        padding: 20px 10px;
        font-size: 14px;
    }
}

/* Media Queries para pantallas medianas */
@media (max-width: 1024px) {
    .imagen-portada-calibrar {
        height: 600px;
        /* Ajuste la altura para pantallas medianas */
    }
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
    .imagen-portada-calibrar {
        height: 400px;
        /* Ajuste la altura para pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .imagen-portada-calibrar {
        height: 300px;
        /* Ajuste la altura para pantallas muy pequeñas */
    }

    .imagen-portada-calibrar img {
        object-fit: cover;
        /* Asegura que la imagen se recorte en lugar de distorsionarse */
    }
}