.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 25px;
    background-color: #000;
    gap: 15%;
  }

  .navbar-menu {
    position: relative;
  }
  
  .menu-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  /*modificar aqui*/
  .menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25px;
    margin-right: 15px;
  }
  
  .menu-icon span {
    background-color: #fff;
    height: 5px;
    width: 30px;
  }
  
  .menu-text {
    color: #fff;
    margin-top: 5px;
    font-size: 20px;
    font: 'azonix';
  }
  
  .menu-dropdown {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #000;
    display: none;
    flex-direction: column;
    padding: 10px;
    z-index: 1000;
  }
  
  .menu-dropdown.open {
    display: flex;
  }
  
  .menu-dropdown a {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
  }

  .menu-dropdown a:hover {
    color: red;
    padding: 15px 20px;
  }
  
  .navbar-logo img {
    height: 100px
  }

  .navbar-contact button  {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    
  }

  .text-contact{
    font-size: 20px;
    margin-top: 23px;
  }


  .mail-icon {
    margin-left: 10px;
    width: 45px;
    height: 45px;
  }

/* Estilos responsive */
@media screen and (max-width: 850px) {
  .navbar {
    gap: 10px;
    /* Reduce el espacio entre elementos */
  }

  .navbar-logo {
    order: -1;
    /* Coloca el logo en la parte superior */
    width:auto;
  }
  .navbar-logo img{
    height: 50px;
  }

  .navbar-menu {
    order: 1;
  }

  .navbar-contact {
    order: 2;
  }
  .text-contact{
    font-size: 15px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 540px) {
  .navbar-logo {
    display: none;
  }
  .text-contact{
    font-size: 15px;
    margin-top: 18px;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    /* Centra el contenido horizontalmente */
  }
    .navbar-logo {
      width: auto;
      display: contents;
    }
    .menu-text {
      font-size: 16px;
    }

    .navbar-contact button {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;

    }

    .text-contact{
      font-size: 14px;
      margin-top: 18px;
      padding-right: 5px;
    }

    .menu-icon {
      margin-right: 10px;
    }

    .mail-icon {
      width: 40px;
      height: 40px;
      margin-left: 1px;
      margin-top: 2px;
    }

  .navbar-contact {
    font-size: 16px;
  }
  .text-contact {
    font-size: 14px;
    margin-top: 20px;
  }

}

@media screen and (max-width: 200px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 5px;
    /* Reduce el padding para ahorrar espacio */
    gap: 2px;
    /* Menor espacio entre los elementos */
  }

  .navbar-logo img {
    height: 50px;
    /* Reduce la altura del logo para adaptarse a pantallas pequeñas */
  }

  .navbar-menu,
  .navbar-contact {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2px 0;
    /* Reduce el margen para minimizar el uso del espacio */
  }

  .menu-icon span {
    height: 2px;
    /* Reduce aún más el grosor de las barras del menú */
    width: 15px;
    /* Reduce el ancho de las barras */
  }

  .menu-text,
  .navbar-contact button {
    font-size: 12px;
    /* Reduce el tamaño del texto */
  }

  .mail-icon {
    width: 20px;
    /* Ajusta el tamaño del icono de correo */
    height: 20px;
    margin-right: 5px;
    /* Reduce el margen del icono */
  }
}