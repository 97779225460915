/* Estilos generales */
.portada-container-calibracion {
    width: 100%;
    height: 590px;
    position: relative;
    overflow: hidden;
}

.portada-container-calibracion img {
    filter: brightness(0.5) opacity(100%) contrast(100%) saturate(100%) hue-rotate(0deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    width: 100%;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    font-weight: 550;
    position: absolute;
    color: white;
}

.text-overlay span {
    color: red;
}

/*imagen calibracion*/
.descripcion-calibracion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    letter-spacing: 0.48px;
    line-height: 33.6px;
    padding: 5%;
    gap: 100px;
}

.texto-calibracion {
    display: flex;
    flex-direction: column;
}

.texto-calibracion p {
    font-size: 18px;
    text-align: justify;
}

.texto-calibracion ul li {
    font-size: 18px;
    text-align: justify;
}

.image-container-calibracion {
    position: relative;
    overflow: hidden;
    justify-self: center;
}

.image-container-calibracion img {
    max-width: auto;
    width: 600px;
    height: 800px;
    box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.4);
}

.highlighted-letter {
    color: red;
}

@media (max-width: 1024px) {
    .portada-container-calibracion {
        height: 400px;
    }

    .descripcion-calibracion {
        grid-template-columns: 1fr;
        /* 1 columna en pantallas pequeñas */
    }

    .image-container-calibracion {
        height: auto;
        /* Ajustar la altura automáticamente en pantallas pequeñas */
    }

    .descripcion-calibracion h2,
    .descripcion-calibracion p {
        text-align: left;
        /* Alinear el texto a la izquierda en pantallas pequeñas */
    }
}

@media (max-width: 768px) {
    .portada-container-calibracion {
            height: 400px;
        }

    .descripcion-calibracion {
        grid-template-columns: 1fr;
        /* 1 columna en pantallas pequeñas */
    }

    .image-container-calibracion {
        height: auto;
        /* Ajustar la altura automáticamente en pantallas pequeñas */
    }

    .descripcion-calibracion h2,
    .descripcion-calibracion p {
        text-align: left;
        /* Alinear el texto a la izquierda en pantallas pequeñas */
    }
}

@media (max-width: 480px) {

    .image-container-calibracion img {
        width: 300px;
        height: 300px;
    }
}