.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.spinner {
    border: 5px solid whitesmoke;
    border-right-color: red;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 0.7s linear infinite;
    margin-right: 0px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* Estilos para el texto */
.loading-container p {
    font-size: 18px;
    color: black;
    font-family: 'Arial';
}

