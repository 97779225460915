.medidor-f-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portada-container {
    width: 100%;
    height: 590px;
    position: relative;
    overflow: hidden;
}

.portada-container img {
    filter: brightness(0.5) opacity(100%) contrast(100%) saturate(100%) hue-rotate(0deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    width: 100%;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    font-weight: 550;
    position: absolute;
    color: white;
}

.text-overlay span {
    color: red;
}

/*descripcion 1111*/

.descripcion-container {
    padding: 50px;
    max-width: 90%;
    margin: 0px auto;
    line-height: 1.6;
    box-sizing: border-box;
}

.descripcion-container p {
    text-justify: distribute;
    letter-spacing: 0.48px;
    font-size: 18px;
    line-height: 33.6px;
    margin-bottom: 5%;
}

.descripcion-container ul {
    list-style: square;
    margin-bottom: 5%;
}

.descripcion-container ul li {
    font-size: 18px;
}

.descripcion-container img {
    align-content: center;
}

/*Descripcion numero 2*/
.descripcion-container-2 {
    width: 80%;
    margin: 0px auto 50px auto;
    padding-left: 50px;
    padding-bottom: 20px;
    padding-top: 20px;
    background: rgba(192, 192, 192, 0.5);
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.descripcion-titulo {
    font-family: 'Arial', sans-serif;
    color: black;
    text-align: center;
}

.resaltado {
    color: #ff4b4b;
}

.descripcion-container-2 p {
    text-align: justify;
    letter-spacing: 0.5px;
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
}

.descripcion-lista {
    display: flex;
    justify-content: space-between;
}

.columna {
    width: 48%;
}

.columna ul {
    list-style: none;
    padding-left: 0;
}

.columna li {
    font-size: 18px;
    line-height: 1.6;
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
}

.columna li::before {
    content: "▪";
    color: red;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 18px;
}

/*imagen medidor de fuerza*/

.imagen-medidor {
    width: 100%;
    height: 400px;
    margin-bottom: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.imagen-medidor img {
    width: 500px;
    height: 400px;
    box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.4);
}

/*contenedores imagenes 2*/

.contenedor-imagenes-medidores {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: whitesmoke;
    box-shadow: 3px 7px 7px rgba(0, 0, 0, 0.3);
    gap: 20px;
    flex-wrap: wrap;
    /* Para asegurar la responsividad */
}

.beneficios {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    /* Espacio entre los div de cada beneficio */
}

.beneficio {
    padding: 10px;
    border-left: 5px solid red;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* Transición suave */
        cursor: pointer;
        /* Cambia el cursor al pasar sobre el elemento */
}

.beneficio:hover {
    transform: translateY(-5px);
    /* Mueve el elemento ligeramente hacia arriba */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Aumenta la sombra para darle profundidad */
}


.beneficio p {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.imagen-contenedor {
    text-align: center;
}

.contenedor-imagenes-medidores img {
    max-width: 100%;
    max-height: 300px;
    border: 2px solid white;
    box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.4);
}

.titulo {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    text-align: center;
}

/* Media queries para pantallas medianas y pequeñas */
@media screen and (max-width: 992px) {
    .portada-container {
        height: 400px;
    }

    .text-overlay {
        font-size: 2.5em;
        top: 45%;
    }

    .descripcion-container {
        padding: 30px;
        font-size: 16px;
    }

    .descripcion-container-2 {
        padding-left: 20px;
    }

    .descripcion-lista {
        flex-direction: column;
    }

    .columna {
        width: 100%;
        margin-bottom: 20px;
    }

   .imagen-medidor {
       width: 100%;
       height: 300px;
       margin-top: 50px;
       margin-bottom: 50px;
       align-items: center;
       display: flex;
       justify-content: center;
   }

   .imagen-medidor img {
       width: 500px;
       height: 300px;
       box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.4);
   }

    .contenedor-imagenes-medidores {
        flex-direction: column;
    }

    .imagen {
        width: 80%;
    }
    
}

@media screen and (max-width: 576px) {
    .portada-container {
        height: 300px;
    }

    .text-overlay {
        font-size: 2em;
        top: 50%;
    }

    .descripcion-container {
        padding: 20px;
        font-size: 14px;
    }

    .descripcion-container-2 {
        padding-left: 10px;
    }

        .imagen-medidor {
            width: 100%;
            height: 300px;
            margin-top: 50px;
            margin-bottom: 50px;
            align-items: center;
            display: flex;
            justify-content: center;
        }
    
        .imagen-medidor img {
            width: 500px;
            height: 300px;
            box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.4);
        }
}