.container-auto {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.portada-container-automatizacion {
    width: 100%;
    height: 590px;
    /* Ajusta según sea necesario */
    position: relative;
    overflow: hidden;
}

.portada-container-automatizacion img {
    filter: brightness(0.5) opacity(100%) contrast(100%) saturate(100%) hue-rotate(0deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    width: 100%;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    font-weight: 550;
    position: absolute;
    /* Asegúrate de usar una fuente adecuada */
    color: white;
    /* Cambia esto si necesitas otro color */
}

.text-overlay span {
    color: red;
    /* El color rojo para las letras específicas */
}

/*seccion inteligen*/

.inteligen-container {
    width: 80%;
    margin: auto;
}

.inteligen-container h1 {
    text-align: center;
    font-size: 2em;
    color: black;
    margin-top: 5%;
    margin-bottom: 4%;
}

.inteligen-container p {
    text-align: center;
    font-size: 18px;
    color: black;
    margin-top: 30px;
    line-height: 30px;
    letter-spacing: 0.48px;
}

.highlighted {
    color: red;
}

.image-container {
    margin-top: 50px;
    margin-bottom: 40px;
    box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.2);
}

.image-container img {
    max-width: 100%;
    height: auto;
    object-fit: fill;
}

/*grid section*/

.section-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 100px;
    padding: 10px;
    border: 20px red;
    margin-bottom: 1%;
    margin-top: 1%;
}

.grid-item {
    padding: 50px;
    display: flex;
    align-items: baseline;
    /*box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);*/
    background-color: white;
    border-top: 3px red solid;
    max-width: 450px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* Transición suave */
        cursor: pointer;
        /* Cambia el cursor al pasar sobre el elemento */
}

.grid-item:hover {
    transform: translateY(-5px);
    /* Mueve el elemento ligeramente hacia arriba */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Aumenta la sombra para darle profundidad */
}

.grid-item p {
    font-size: 18px;
    color: black;
    margin-left: 10px;
    line-height: 33.6px;
    letter-spacing: 0.48px;
}
/*ventajas section*/
/* Contenedor principal de la sección de ventajas */
.ventajas-section {
    background: white;
    /* Fondo suave con tono rojo claro */
    text-align: center;
    max-width: 600px;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 3%;
}

/* Título de la sección */
.ventajas-section h2 {
    color: black;
    /* Rojo fuerte para el título */
    margin-bottom: 20px;
    text-transform: uppercase;
}

/* Contenedor de la grilla de ventajas */
.ventajas-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Diseño flexible */
    gap: 20px;
}

/* Elementos individuales de la grilla */
.ventaja-item {
    background-color: #fff;
    border-left: 5px solid red;
    /* Línea roja en el lado izquierdo */
    padding: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Efecto hover */
.ventaja-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Icono de ventaja */
.ventaja-icon {
    color: red;
    /* Color rojo para los íconos */
    font-size: 24px;
    margin-right: 10px;
}

/* Texto de cada ventaja */
.ventaja-item p {
    text-align: left;
    margin: 0;
    font-size: 18px;
    color: black;
    /* Color gris oscuro para el texto */
}

.confia {
    text-align: center;
    margin-top: 10%;
    font-size: 18px;
    color: black;
    /* Color gris oscuro para el texto */
}

.container-img-generadores {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.container-img-generadores img {
    object-fit: cover;
    box-shadow: 4px 7px 7px rgba(0, 0, 0, 0.2);
}

.text-2-overlay {
    text-justify: auto;
    top: 20%;
    left: 35%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    font-weight: 550;
    position: absolute;
    /* Asegúrate de usar una fuente adecuada */
    color: black;
    /* Cambia esto si necesitas otro color */
}

/* Para pantallas pequeñas (móviles, menos de 768px) */
@media (max-width: 768px) {
    .portada-container-automatizacion {
        height: 300px;
        /* Altura más pequeña */
    }

    .text-overlay {
        font-size: 1.8em;
        /* Reduce aún más el tamaño de la fuente */
        top: 30%;
        /* Ajusta la posición */
    }

    .inteligen-container {
        width: 90%;
        /* Amplía un poco el contenedor */
    }

    .inteligen-container h1 {
        font-size: 1.5em;
        /* Reduce el tamaño del título */
    }

    .inteligen-container p {
        font-size: 0.9em;
        /* Reduce el tamaño del texto */
    }

    .grid-container {
        grid-template-columns: 1fr;
        /* Una columna */
        gap: 20px;
        /* Espacio más reducido */
        margin-bottom: 30px;
        /* Menor margen inferior */
    }

    .grid-item {
        padding: 20px;
        /* Menor padding */
    }

    .grid-item p {
        font-size: 16px;
        /* Texto más pequeño */
    }
}

/*container-img-generadores*/

@media (max-width: 768px) {
    .text-2-overlay {
        font-size: 1.5em;
        top: 20%;
        /* Centrar verticalmente en tabletas */
        left: 30%;
        transform: translate(-50%, -50%);
    }
        .container-img-generadores img {
            width: 90%;
        }
}

@media (max-width: 480px) {
    .text-2-overlay {
        font-size: 1.2em;
        top: 20%;
        /* Centrar verticalmente en móviles */
        left: 30%;
        transform: translate(-50%, -50%);
        /* Ajustar el ancho del texto para pantallas pequeñas */
    }
        .container-img-generadores img {
            width: 90%;
        }
}