.footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%;
  justify-content: space-around;
  width: 90%;
}

.contact-info {
  margin-bottom: 20px;
  flex-grow: 1;
}

.contacto {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contacto svg{
  margin-right: 10px;
}

.direccion {
  font-size: 24px;
  margin-bottom: 10%;
}

.services {
  text-align: center;
  margin-bottom: 20px;
  flex-grow: 2;
}

.titulo-servicios {
  font-weight: normal;

}

.services ul {
  list-style-type: none;
  font-size: 18px;
  padding: 0;
}

.services ul li {
  padding-top: 15px;
  color: rgba(255, 255, 255, 0.6);
  /* Blanco con 60% de opacidad */
}

.subir-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 10%;
  flex-grow: 1;
}

.subir-icon svg {
  width: 40px;
  height: 45px;
}

.bottom-side {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
}

.copyright {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  /* Blanco con 60% de opacidad */
  text-align: center;
  align-self: center;
  margin-bottom: 10px;
}

.social-media p {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.social-media {
  align-self: center;
}

.electrica-montajes {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  align-self: center;
}

/* Media Queries para pantallas más grandes */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 3%;
  }

  .contact-info,
  .services {
    text-align: left;
    align-items: flex-start;
  }

  .direccion {
    font-size: 36px;
  }

  .bottom-side {
    flex-direction: row;
    justify-content: space-around;
  }

  .copyright {
    font-size: 12px;
    text-align: left;
  }

  .social-media {
    text-align: left;
  }

  .electrica-montajes {
    text-align: left;
  }
}

@media (max-width: 760px) {
  .footer-content {
    flex-direction: column;
    margin-bottom: 3%;
  }

  .contact-info,
  .services {
    text-align: center;
  }

  .direccion {
    font-size: 36px;
  }

  .bottom-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-side p{
    text-align: center;
  }

  .copyright {
    font-size: 12px;
    text-align: left;
  }

  .social-media {
    text-align: left;
  }

  .electrica-montajes {
    text-align: left;
  }

  .titulo-servicios {
    text-align: center;
  }

  .subir-icon {
    margin-bottom: 0px;
  }
}

/* Media Queries para pantallas muy pequeñas (móviles) */
@media (max-width: 480px) {
  .direccion {
    font-size: 20px;
  }

  .contact-info,
  .services {
    text-align: center;
  }

  .services ul li {
    font-size: 12px;
  }

    .subir-icon {
      margin-bottom: 0px;
    }

  .subir-icon svg {
    width: 30px;
    height: 35px;
  }

    
}