.websupervisor-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    max-width: 200px;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.text-section {
    flex: 1;
    max-width: 600px;
    margin-right: 100px;
    letter-spacing: 0.48px;
    line-height: 33.6px;
    font-size: 18px;
    color: black;
}

.text-section h2 {
    color: #e60000;
}

.text-section h3 {
    margin-top: 20px;
}

.text-section ul {
    list-style: decimal;
    padding: 0;
}

.text-section ul ul {
    list-style-type: lower-alpha;
    /* Estilo de letras minúsculas para las sublistas */
    margin-left: 20px;
}

.text-section .highlight {
    color: red;
    font-weight: bold;
}

.image-section {
    flex: 1;
    text-align: center;
}

.dashboard-image {
    max-width: 100%;
    height: 600px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*section ventajas*/

.ventajas-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    color: #333;
    margin-top: 50px;
}

.image-section-2 {
    flex: 1;
    text-align: center;
    margin-right: 100px;
}

.ventajas-image {
    max-width: 100%;
    height: 600px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-section-2 {
    flex: 1;
    max-width: 600px;
    line-height: 33.6px;
    letter-spacing: 0.48px;
    font-size: 18px;
    color: black;
}

.text-section-2 h2 {
    font-size: 24px;
    color: #333;
}

.text-section-2 .highlight {
    color: red;
    font-weight: bold;
}

.text-section-2 ol {
    padding-left: 20px;
    list-style-type: decimal;
}

.text-section-2 ol li {
    margin-bottom: 15px;
}

.text-section a {
    color: #e60000;
    text-decoration: none;
}

/* Pantallas medianas (tabletas) */
@media (min-width: 768px) and (max-width: 1023px) {

    .content,
    .ventajas-container {
        flex-direction: column;
        align-items: center;
    }

    .text-section,
    .text-section-2,
    .image-section,
    .image-section-2 {
        max-width: 90%;
        margin: 20px 0;
        text-align: left;

    }

    .dashboard-image,
    .ventajas-image {
        height: auto;
    }
}

/* Pantallas pequeñas (móviles) */
@media (max-width: 767px) {

    .content,
    .ventajas-container {
        flex-direction: column;
        align-items: center;
    }

    .text-section,
    .text-section-2,
    .image-section,
    .image-section-2 {
        max-width: 100%;
        margin: 10px 0;
        text-align: left;
    }

    .text-section,
    .text-section-2 {
        margin-right: 0;
        padding: 10px;
    }

    .dashboard-image,
    .ventajas-image {
        height: auto;
    }

    .text-section h2,
    .text-section-2 h2 {
        font-size: 20px;
    }
}