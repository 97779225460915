.monitoreo-a-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.portada-container {
    width: 100%;
    /* Ajusta según sea necesario */
    position: relative;
    overflow: hidden;
}

.portada-container img {
    filter: brightness(0.5) 
            opacity(100%) 
            contrast(100%) 
            saturate(100%) 
            hue-rotate(0deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    width: 100%;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    font-weight: 550;
    position: absolute;
    /* Asegúrate de usar una fuente adecuada */
    color: white;
    /* Cambia esto si necesitas otro color */
}

.text-overlay span {
    color: red;
    /* El color rojo para las letras específicas */
}


/*seccion DESCRICION*/

.descripcion-container-1 {
    padding: 50px;
    max-width: 80%;
    /* Limitar el ancho máximo para pantallas grandes */
    margin: auto;
    line-height: 33.6px;
    box-sizing: border-box;
    /* Asegura que el padding no afecte el tamaño total */
    letter-spacing: 0.48px;
    
}

.descripcion-container-1 p{
    font-size: 18px;
    margin-bottom: 0;
}

.descripcion-container-1 ul li {
    font-size: 18px;
}

/*seccion ESTACIONES*/

.estaciones-container {
    display: flex;
    justify-content: space-around;
    background-color: #000;
    /* Black background to match slide */
    padding: 70px;
    color: #fff;
    /* White text */
    letter-spacing: 0.48px;
    line-height: 26px;
    align-items: center;
}
/*
.estaciones-content {
    padding-left: 1%;
}*/

.title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 50px;
}

.title-highlight {
    color: red;
    /* Red color for the letter 'E' */
}

.underline {
    text-decoration: underline;
}

.estaciones-list {
    list-style-type: none;
    /* Remove default bullet points */
    padding: 0;
    font-size: 18px;
}

.estaciones-list li {
    position: relative;
    padding-left: 20px;
    padding-bottom: 20px;
    /* Space for custom bullet point */
}

.estaciones-list li::before {
    content: '•';
    /* Custom bullet point */
    color: red;
    position: absolute;
    left: 0;
    top: 0;
}

.estaciones-image img {
    max-width: 100%;
    width: 500px;
    height: 500px;
    object-fit: cover;
    /*border: 2px solid #fff;*/
    /* Rounded corners */
}

/*Panel visualizacion*/
.panel-container {
    width: 90%;
    margin: auto;
    padding: 20px;
    letter-spacing: 0.48px;
    line-height: 26px;
}

.panel-container h1 {
    text-align: center;
    font-size: 2em;
    color: black;
}

.highlighted {
    color: red;
}

.instructions {
    list-style: none;
    padding: 0;
    margin: 50px 100px;
}

.instructions li {
    margin: 10px 0;
    font-size: 1.2em;
    color: black;
}

.image-container {
    text-align: center;
    margin-top: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-container img {
    max-width: 100%;
    height: auto;
}

/* Responsive para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .text-overlay {
        font-size: 2em;
        /* Reducir el tamaño del texto */
    }

    .portada-container {
        max-height: 400px;
        /* Ajustar altura máxima en pantallas pequeñas */
    }
    
    .descripcion-container-1 {
        width: 90%;
        /* Aumentar el ancho en pantallas más pequeñas */
        padding: 15px;
        /* Reducir el padding */
    }
        .estaciones-image img {
            max-width: 100%;
            width: 500px;
            height: 450px;
            object-fit: cover;
            /*border: 2px solid #fff;*/
            /* Rounded corners */
        }
}

@media screen and (max-width: 480px) {
    .text-overlay {
        font-size: 1.5em;
        /* Más pequeño para pantallas extra pequeñas */
    }

    .portada-container {
        max-height: 300px;
        /* Ajustar la altura máxima en pantallas extra pequeñas */
    }

    .descripcion-container-1 {
        width: 95%;
        /* Aumentar aún más el ancho */
        padding: 10px;
        /* Reducir más el padding */
        line-height: 1.4;
        /* Ajustar el espacio entre líneas */
    }
}

/* ESTACIONES */
@media (max-width: 768px) {
    .estaciones-container {
        flex-direction: column;
        align-items: center;
        /* Centra los elementos en pantallas pequeñas */
    }

    .estaciones-content,
    .estaciones-image {
        margin-bottom: 20px;
        /* Añade espacio entre los elementos */
    }

    .title {
        font-size: 1.2em;
        margin-bottom: 30px;
    }
}

/* Responsivo para tablets */
@media (max-width: 768px) {
    .panel-container {
        width: 90%;
        padding: 15px;
    }

    .panel-container h1 {
        font-size: 1.5em;
    }

    .instructions li {
        font-size: 1.1em;
        text-align: center;
    }
}

/* Responsivo para móviles */
@media (max-width: 480px) {

    .panel-container h1 {
        font-size: 1.2em;
    }

    .instructions {
        margin: 30px 0;
        text-align: center;
    }

    .instructions li {
        font-size: 1em;
    }
}