/* Estilos generales */
.luces-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portada-container-luces {
    width: 100%;
    height: 590px;
    position: relative;
    overflow: hidden;
}

.portada-container-luces img {
    filter: brightness(0.5) opacity(100%) contrast(100%) saturate(100%) hue-rotate(0deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    width: 100%;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    font-weight: 550;
    position: absolute;
    color: white;
}

.text-overlay span {
    color: red;
}

/* Sección de panel de luces */
.panel-luces-navegacion {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    color: white;
    font-family: Arial, sans-serif;
    width: 100%;
}

.text-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.texto-panel {
    text-align: justify;
    line-height: 33.6px;
    letter-spacing: 0.48px;
    padding-top: 3%;
}

.container-interfaz-caracteristicas {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    padding-bottom: 3%;
}

.panel-luces-navegacion ol {
    list-style-type: decimal;
}

.panel-luces-navegacion ol li {
    list-style-type: decimal;
    margin-bottom: 30px;
    line-height: 33.6px;
    letter-spacing: 0.48px;
    margin-left: 10%;
}

.panel-luces-navegacion ol li::marker {
    color: red;
}

.imagen-panel {
    height: auto;
    margin-top: 3%;
    margin-right: 30%;
}

.imagen-panel img {
    width: 100%;
    height: 400px;
}

/*seccion caracteristicas*/

.caracteristicas-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    letter-spacing: 0.48px;
    line-height: 33.6px;
    width: 100%;
    padding-top: 3%;
    padding-bottom: 1%;
}

.caracteristicas-container h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-left: 10%;
}

.caracteristicas-container ul {
    font-size: 18px;
    padding-left: 10%;
}

.highlighted-letter {
    color: red;
}

/*funcionamiento*/

.funcionamiento-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    letter-spacing: 0.48px;
    line-height: 33.6px;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.funcionamiento-container h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-left: 10%;
}

.highlighted-letter {
    color: red;
}

.funcionamiento-container p {
    font-size: 18px;
    text-align: justify;
    padding-left: 10%;
    padding-bottom: 3%;
}

/*portada luz roja*/
.imagen-portada-luz {
    width: 100%;
    height: 800px;
    /* Ajusta según sea necesario */
    position: relative;
    overflow: hidden;
}

.imagen-portada-luz img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    bottom: 0px;
}

/* Media Queries para pantallas medianas */
@media (max-width: 1024px) {
    .text-overlay {
        font-size: 2.5em;
    }

    .container-interfaz-caracteristicas {
        grid-template-columns: 1fr;
    }

    .imagen-panel {
        margin-right: 0;
    }
        .texto-panel {
            padding: 20px;
        }
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
    .portada-container-luces {
        height: 400px;
    }

    .text-overlay {
        font-size: 2em;
        top: 35%;
    }

    .panel-luces-navegacion {
        padding: 20px;
        max-width: fit-content;
    }

    .imagen-panel {
        width: 100%;
        margin-top: 2%;
        margin-right: 0;
    }

        .imagen-panel img {
            width: 100%;
            height: 300px;
        }

    .texto-panel {
        padding: 20px;
    }

    .panel-luces-navegacion ol li {
        margin-left: 5%;
        margin-bottom: 20px;
    }
}

/* Media Queries para pantallas medianas */
@media (max-width: 768px) {

    .caracteristicas-container h2,
    .funcionamiento-container h2 {
        font-size: 28px;
        padding: 5%;
    }

    .caracteristicas-container ul,
    .funcionamiento-container p {
        font-size: 16px;
        padding: 5%;
    }
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 480px) {

    .caracteristicas-container h2,
    .funcionamiento-container h2 {
        font-size: 24px;
        padding: 5%;
    }

    .caracteristicas-container ul,
    .funcionamiento-container p {
        font-size: 14px;
        padding: 5%;
    }
}

/* Media Queries para pantallas medianas */
@media (max-width: 1024px) {
    .imagen-portada-luz {
        height: 500px;
        /* Ajuste la altura para pantallas medianas */
    }
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
    .imagen-portada-luz {
        height: 300px;
        /* Ajuste la altura para pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .imagen-portada-luz {
        height: 200px;
        /* Ajuste la altura para pantallas muy pequeñas */
    }

    .imagen-portada-luz img {
        object-fit: cover;
        /* Asegura que la imagen se recorte en lugar de distorsionarse */
    }
}