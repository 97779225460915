.rect-fade-enter {
    opacity: 0;
    /* Inicia con opacidad 0 */
}

.rect-fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
    /* Cambia gradualmente a opacidad 1 */
}

.rect-fade-exit {
    opacity: 1;
    /* Inicia con opacidad 1 */
}

.rect-fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    /* Cambia gradualmente a opacidad 0 */
}