/* Contenedor principal de la sección */
.home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* Espaciado para pantallas pequeñas */
  box-sizing: border-box;
  /* Asegura que el padding no desborde */
}

/* Contenedor principal del carrusel */
.image-content.modern-carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  /* Pantalla completa */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Contenedor de cada slide */
.carousel-slide {
  position: relative;
  display: flex;
  align-items: center;
  /* Centra verticalmente */
  justify-content: center;
  /* Centra horizontalmente */
  height: 100%;
  /* Ocupa el 100% de la altura */
}

/* Imágenes del carrusel */
.carousel-image {
  max-width: 100%;
  /* Se adapta al ancho máximo */
  max-height: 100%;
  /* Se adapta a la altura máxima */
  object-fit: contain;
  /* Ajusta las imágenes al contenedor sin deformarlas */
  transition: transform 0.5s ease;
  filter: brightness(0.5) opacity(100%) contrast(100%) saturate(100%) hue-rotate(0deg);
}

.carousel-image:hover {
  transform: scale(1.05);
  /* Efecto de zoom al pasar el mouse */
}

.text-overlay.modern-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  font-family: "Roboto", sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.text-overlay h1 {
  font-size: 4rem;
  line-height: 1.5;
}

.text-overlay .highlight {
  color: #ff4c4c;
  /* Rojo moderno */
  font-weight: bold;
}

/* Animaciones */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
  
  .bottom-focus {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: bottom;
          /* Esto mueve la imagen para mostrar más la parte inferior */
  }

    /* Controles del carrusel */
    .carousel-controls {
      position: absolute;
      top: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      transform: translateY(-50%);
      pointer-events: none;
      /* No bloquea la interacción con el carrusel */
    }
  
    .control {
      background-color: rgba(3, 3, 3, 0.5);
      color: white;
      border: none;
      padding: 10px 15px;
      font-size: 24px;
      cursor: pointer;
      pointer-events: all;
      /* Habilita interacción en los botones */
      z-index: 10;
      border-radius: 50%;
      transition: background-color 0.3s ease;
    }
  
    .control:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  
    /* Botón izquierdo */
    .control.prev {
      position: absolute;
      left: 10px;
    }
  
    /* Botón derecho */
    .control.next {
      position: absolute;
      right: 10px;
    }
  
    /* Indicadores del carrusel */
    .carousel .control-dots {
      bottom: 10px;
    }
  
    .carousel .control-dots .dot {
      background: rgba(255, 255, 255, 0.5);
    }
  
    .carousel .control-dots .dot.selected {
      background: rgba(255, 255, 255, 1);
    }

/* Contenedor del botón */
.cta-button-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Estilo del botón sin bordes */
.cta-button {
  background-color: transparent;
  /* Fondo transparente */
  color: red;
  /* Texto en color rojo */
  border: 2px solid red;
  /* Bordes sutiles delgados */
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0px;
  /* Bordes redondeados */
  cursor: pointer;
  transition: all 0.5s ease;
  /* Transición suave */
}

/* Efecto hover */
.cta-button:hover {
  background-color: red;
  /* Fondo rojo cuando pasa el mouse */
  color: white;
  /* Texto blanco */
  border-color: transparent;
  /* Ocultar el borde */
  transform: scale(1.05);
}

/* Estilo responsive */
@media (max-width: 1200px) {
  .cta-button {
    font-size: 1.1rem;
    /* Reducir tamaño de fuente en pantallas más pequeñas */
    padding: 12px 25px;
    /* Ajustar el tamaño del padding */
  }
}

@media (max-width: 768px) {
  .cta-button {
    font-size: 1rem;
    /* Ajustar tamaño de fuente aún más pequeño */
    padding: 10px 20px;
    /* Ajustar padding */
  }
}

@media (max-width: 480px) {
  .cta-button {
    font-size: 0.9rem;
    /* Reducir el tamaño de fuente para dispositivos muy pequeños */
    padding: 8px 15px;
    /* Reducir padding */
  }
}

@media (max-width: 360px) {
  .cta-button {
    font-size: 0.8rem;
    /* Aún más pequeño */
    padding: 6px 12px;
    /* Ajustar padding para pantallas pequeñas */
  }
}
    
  .text-overlay span {
    color: red;
    /* El color rojo para las letras específicas */
  }

  .text-overlay.ultimate-gear{
    font-size: 60px;
  }

/* Estilo de la flecha */
.arrow-icon {
  color: #FC0505;
  font-size: 2rem;
  /* Mayor tamaño para más presencia */
  margin-right: 8px;
}

/* Contenedor de texto */
.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: #333;
  background: linear-gradient(135deg, rgba(252, 5, 5, 0.1), rgba(5, 5, 252, 0.1));
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.text-content:hover {
  transform: translateY(-10px);
  /* Desplazamiento hacia arriba en hover */
  opacity: 0.9;
  /* Ligera opacidad en hover */
}

/* Estilo del texto principal */
.welcome-text {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  /* Fuente moderna */
  letter-spacing: 1px;
  /* Espaciado de letras */
}

.welcome-text .brand-name {
  color: #FC0505;
  font-weight: bold;
  /* Hace que el nombre de la marca resalte más */
}

/* Animación del cursor parpadeante */
.blinking-cursor {
  font-weight: bold;
  color: #FC0505;
  animation: blink 1s step-end infinite;
}

/* Animación de parpadeo */
@keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: #FC0505;
  }
}

/* Estilo para la descripción */
.description {
  font-size: 1.5rem;
  color: #555;
  max-width: 800px;
  margin-top: 20px;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

/* Estilo para resaltar texto importante */
.description b {
  color: #FC0505;
  font-weight: bold;
}

/* Resaltado de texto específico */
.highlight {
  color: #FC0505;
}

/* Estilo responsive */
/* Estilo responsive */
@media (max-width: 768px) {
  .text-content {
      padding: 0px;
    }
  .welcome-text {
    font-size: 20px;
    margin-bottom: 1%;
  }

  .description {
    font-size: 1rem;
    padding: 30px;
    margin-top: 1%;
  }
}

@media (max-width: 480px) {
  .welcome-text {
    font-size: 18px;
  }

  .description {
    font-size: 0.9rem;
    padding: 30px;
  }
}

  .services-section {
      padding: 40px 20px;
      background-color: #fff;
  }

  .services-section h2 {
      text-align: center;
      color: #000;
      margin-bottom: 20px;
      font-size: 2rem;
  }

  .services-section h2 span {
      color: #FC0505;
  }

  .services-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
  }

  .service-item {
      border: 2px solid #FC0505;
      padding: 35%;
      text-align: center;
      border-radius: 0px;
      transition: transform 0.3s;
      position: relative;
      overflow: hidden;
      /* Crear relación de aspecto cuadrada */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      text-decoration: none;
  }

  .service-item svg {
      max-width: 50px;
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
  }

  .service-item h3 {
      font-size: 1.2rem;
      margin: 60px 0 10px;
      /* Asegurar que el título esté centrado en la sección */
      color: #000;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
  }

  .service-item p {
      font-size: 1rem;
      color: black;
      position: absolute;
      bottom: 5%;
      left: 50%;
      top: 60%;
      transform: translate(-50%);
      width: 80%;
  }

  .service-item:hover {
      transform: scale(1.05);
      border-color: red;
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }

  .services-grid> :nth-child(7) {
    grid-column: 2;
  }

  /*carrousel section*/

    .imagen-carrousel {
      width: 100%;
      height: 700px;
      /* Ajusta según sea necesario */
      position: relative;
      overflow: hidden;
    }

  .imagen-carrousel img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    bottom: 0px;
    filter: brightness(0.4) opacity(100%) contrast(100%) saturate(90%) hue-rotate(0deg) drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
  }

/* Tablets */
@media (max-width: 1024px) {
  .image-content.modern-carousel {
    height: 600px;
    /* Reduce la altura del carrusel */
  }

  .carousel-image {
    object-fit: contain;
    /* Las imágenes seguirán visibles sin recortes */
    max-width: 100%;
    height: 100%;
  }

  .text-overlay h1 {
    font-size: 4rem;
    /* Texto más pequeño en tablets */
    padding: 10px;
  }

}

/* Smartphones grandes */
@media (max-width: 768px) {
  .image-content.modern-carousel {
    height: 500px;
    /* Reduce aún más la altura */
  }

  .carousel-image {
    object-fit: cover;
    /* Las imágenes llenarán el contenedor */
  }

  .text-overlay h1 {
    font-size: 3.5rem;
    /* Texto más pequeño en smartphones grandes */
    padding: 10px;
    /* Más compacto */
  }

}

/* Celulares pequeños (Pantallas muy pequeñas) */
@media (max-width: 480px) {
  .home-section {
    text-align: center;
    /* Asegura el centrado en pantallas pequeñas */
  }

  .image-content.modern-carousel {
    height: 100%;
    /* Altura más reducida */
  }

  .carousel-image {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    /* Rellena el contenedor */
  }

  .text-overlay h1 {
    font-size: 3rem;
    /* Texto aún más pequeño en celulares */
    padding: 10px;
  }

}






  /* Media Queries para hacer el diseño responsive */
  /*primera parte del home*/
    /* Estilos responsive */
    @media screen and (max-width: 768px) {
      .text-content {
        padding-right: 0;
        text-align: center;
        /* Centra el texto en pantallas pequeñas */
        margin-bottom: 30px;
        /* Agrega espacio debajo del texto */
      }
  
      .text-content h1 {
        font-size: 36px;
        /* Reduce el tamaño del título */
      }
  
      .description {
        font-size: 16px;
        /* Reduce el tamaño de la descripción */
        line-height: 1.4;
      }
    }

            @media screen and (max-width: 480px) {
              .title {
                font-size: 1.8rem;
              }
      
              .description {
                font-size: 0.8rem;
              }
      
            }
@media screen and (max-width: 300px) {
    .text-content {
      padding-right: 0;
      text-align: center;
      /* Centra el texto en pantallas pequeñas */
      margin-bottom: 30px;
      /* Agrega espacio debajo del texto */
    }
  
    .text-content h1 {
      font-size: 18px;
      margin-top: 15%;
      /* Reduce el tamaño del título */
    }

    .welcome-text {
      font-size: 10px;
    }
  
    .description {
      font-size: 8px;
      /* Reduce el tamaño de la descripción */
      line-height: 1.4;
    }

  .title {
    font-size: 1.8rem;
  }

  .description {
    font-size: 0.8rem;
  }

}

@media screen and (max-width: 1200px) {
.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .service-item {
    border: 2px solid #FC0505;
    padding: 45%;
    text-align: center;
    border-radius: 0px;
    transition: transform 0.3s;
    position: relative;
    overflow: visible;
    /* Crear relación de aspecto cuadrada */
  }

  .service-item svg {
    max-width: 50px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }

  .service-item h3 {
    font-size: 1.2rem;
    margin: 60px 0 10px;
    /* Asegurar que el título esté centrado en la sección */
    color: #000;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .service-item p {
    font-size: 1rem;
    color: black;
    position: absolute;
    bottom: 5%;
    left: 50%;
    top: 60%;
    transform: translate(-50%);
    width: 80%;
  }
}

    @media screen and (max-width: 1024px) {
      .service-item p {
          display: none;
        }
    }


  /* Responsiveness services*/
  @media (max-width: 768px) {
      .services-grid {
          grid-template-columns: 1fr;
          margin: 20px;
      }

      .service-item {
          padding: 30%;
      }

      .service-item h3 {
          font-size: 1.5rem;
          top: 35%;
      }

      .service-item p {
          top: 65%;
          font-size: 1rem;
      }

      .service-item-last {
          grid-column: auto;
          grid-row: auto;
      }

    }

  @media (max-width: 480px) {
      .services-section h2 {
          font-size: 1.5rem;
      }

      .service-item {
          padding: 30%;
      }

      .service-item h3 {
          font-size: 1.2rem;
          top: 40%;
      }

      .service-item p {
          display: none;
      }
  }
  /* seccion de imagen carrusel*/

    @media (max-width: 768px) {
      .imagen-carrousel {
        height: 60vh;
      }
  
      .text-overlay {
        font-size: 2em;
        /* Tamaño de fuente base */
      }
    }

@media (max-width: 768px) {
  .services-grid> :nth-child(7) {
    grid-column: auto;
  }
}
@media (max-width: 480px) {
  .services-grid> :nth-child(7) {
    grid-column: auto;
  }
}