body {
  margin: 0;
  font-family: 'UltimateGear', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Azonix';
  src: url('./fonts/Azonix.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UltimateGear';
  src: url('./fonts/UltimateGear-EaV0g.otf') format('opentype'); /* Ruta al archivo de la fuente UltimateGear */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UltimateGearSlant';
  src: url('./fonts/UltimateGearSlant-ax9eJ.otf') format('opentype'); /* Ruta al archivo de la fuente UltimateGearSlant */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: Arial, sans-serif; /* Usa la fuente OTF con Arial como respaldo */;
}

/* Clases específicas para fuentes */
.ultimate-gear {
  font-family: 'UltimateGear', Arial, sans-serif; /* Usa UltimateGear como principal */
}

.ultimate-gear-slant {
  font-family: 'UltimateGearSlant', Arial, sans-serif; /* Usa UltimateGearSlant como principal */
}

.azonix {
  font-family: 'Azonix', Arial, sans-serif; /* Usa la fuente específica en ciertos elementos */
}

.arial {
  font-family: Arial;
  /* Usa la fuente específica en ciertos elementos */
}

img {
  max-width: 100%;
}